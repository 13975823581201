<template>
  <div class="d-flex justify-content-between new-block">
    <h2>
      {{
        $t(
          "DependencyFields.DetailDependencyField",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </h2>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="fieldRelationList"
  />
</template>
<script>
export default {
  name: "CustomObjectDetailDependencyField",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "CustomObjectDetailDependencyField",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-DependencyDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [],
      },
      gridColumns: [
        {
          text: this.$t(
            "DependencyFields.ControllingField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "controllingFieldName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "DependencyFields.DependentField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "dependentFieldName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "DependencyFields.Value",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "value",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      fieldRelationList: [],
      controllingFieldName: "",
      dependentFieldName: "",
    };
  },
  methods: {
    getDependencyFields() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-DependencyFieldEdit?id={0}",
            this.$route.params.dependencyFieldId
          )
        )
        .then((response) => {
          this.fieldRelationList = response.data.items;
          this.fieldRelationList.forEach((el) => {
            el.controllingFieldName = response.data.controllingFieldName;
            el.dependentFieldName = response.data.dependentFieldName;
            el.publicId = response.data.publicId;
          });
          this.controllingFieldName = response.data.controllingFieldName;
          this.dependentFieldName = response.data.dependentFieldName;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getDependencyFields();
  },
  watch: {
    "$route.params.customObjectId"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.getDependencyFields();
      }
    },
  },
};
</script>
